export default {
    zh: {
        title: '催收管理',
        allTitle: '催收任务管理',
        mytitle: '我的催收任务',
        historytitle: '历史催收任务',
        performancetitle: '催收绩效',
        createTime: '入催时间',
        followNum: '跟进次数',
        followUserName: '跟进人',
        followResult: '跟进结果',
        followTime: '跟进时间',
        repaymentIntention: '还款意愿',
        collectionTaskStatus: '催收任务状态',
        batchReplacementFollowStaff: '批量更换跟进人员',
        transferOrder: '调单',
        userName: '催收人',
        mobile:'手机号',
        collectionNumber: '分单总数',
        collectionAmount: '分单金额',
        pendingNum: '待处理总数',
        processNum: '跟进中总数',
        cancelNum: '取消总数',
        succedNumber: '催回总数',
        extendNumber: '展期数',
        succedAmount: '催回金额',
        succedRate: '催回成功率',
        paymentamt: '催回金额',
        amountPaidAmt: '催回金额(实际)',
        naNum: '客户自主还款数',
        approval: '审批',
        endTime: '到期日',
        settleTime: '出催时间',
        firstOverdueCount: '1-3天回款总数',
        firstOverdueAmt: '1-3天回款金额',
        secOverdueCount: '4-7天回款总数',
        secOverdueAmt: '4-7天回款金额',
        thridOverdueCount: '8天以上回款总数',
        thridOverdueAmt: '8天以上回款金额',
        applyReductionAmt: '申请减免金额',
        balFee: '剩余滞纳金',
        countResult: '统计结果',
        countResultRate: '统计结果占比',
        userId: '催收员编号',
        groupName: '催收分组',
        linkCondition: '通讯录和联系人情况',
        custContractCondition:'客户本人联系情况',
        amountSuccedRate: '催回成功率（金额）',
        amountPaidRate: '催回率（还款金额）',
        ym: '日期',
        ifAmtRate: '占比',
        ifAmtFirstOverdueRate: '首逾率',
        ifAmtUnSettleRate: '坏账率',
        ifAmtEarlySettledRate: '提前结清率',
        ifAmtOverdueSettledRate: '逾期结清率',
        ifAmtExtendsionSettledRate: '展期结清率',
        ifAmtOverdueExtendsionSettledRate: '逾期展期结清率',
        ifAmtSettledRate: '正常结清率',
        ifLoanCount: '借款类型',
        operationTime: '操作时间',
        operationUserName: '操作人名称',
        operationUserMobile: '操作人账号',
        operationType: '操作类型',
        taskIdOld: '任务Id-老',
        taskIdNew: '任务Id-新',
        orderInfoId: '订单id',
        custInfoId: '客户编号',
        waitingForCollection: '待跟进',
        answeredByThemSelf: '本人接听',
        notInPerson: '非本人接听',
        noAnswer: '无人接听',
        emptyNumber: '空号',
        turnedOff: '关机',
        unavailable: '停机',
        whatsappPromisesToPayBackOnTime: 'Whatsapp承诺准时还款',
        WhatsappDidNotReply: 'Whatsapp未回复',
        NoTieneCuentaDeWhatsapp: '没有whatsapp账号',
        haveAWishAndCanReturnTheLoan: '有意愿有能力还款',
        haveAWishButCantReturnTheLoan: `有意愿还款但无能力还款`,
        canReturnTheLoanButRefuseToPayIt: '有能力但拒绝还款',
        canReturnTheLoanButWantToConsult: '有能力但需协商',
        cantJudgeItForNow: `暂无法判定`,
        LaPersonaDeContactoPuedeComunicarleAlClienteParaElReembolso: '联系人可以通知客户还款',
        ElContactoNoPuedeComunicarse: '联系人无法沟通',
        LaInformaciónDelContactoEsFalsa: '通讯录联系人信息造假',
        createdTime: '生成时间',
        id: 'ID',
        remark: '描述',
    },
    en: {
        title: 'Collection Management',
        allTitle: 'All Collection Tasks',
        mytitle: 'My Collection Tasks',
        historytitle: 'History Collection Tasks',
        performancetitle: 'Collection Performance',

        createTime: 'Created on',
        followNum: 'Follow number',
        followUserName: 'Persona a cargo',
        followResult: 'FollowResult',
        followTime: 'Follow Time',
        repaymentIntention: 'Repayment Intention',
        collectionTaskStatus: 'Task Statusa',
        batchReplacementFollowStaff: 'Batch Replacement Follow Staff',
        transferOrder: 'Transfer Order',
        userName: 'User Name',
        collectionNumber: 'Collection Number',
        collectionAmount: 'Collection Amount',
        pendingNum: 'Pending number',
        processNum: 'Process number',
        cancelNum: 'Cancel number',
        succedNumber: 'Succed Number',
        extendNumber: 'Extend Number',
        succedAmount: 'Succed Amount',
        succedRate: 'Succed Rate',
        approval: 'Approval',
        endTime: 'Due date',
        applyReductionAmt: 'apply reduction amount',
        balFee: 'balance late fee',
        countResult: 'Count Result',
        countResultRate: 'Count Result Rate',
        userId: 'user id',
        groupName: 'group name',
        linkCondition: 'Contact Information Base',
        custContractCondition:'Customer contact information',
        amountSuccedRate: 'Amount Succed Rate',
        ym: 'ym',
        ifAmtRate: 'Amount rate',
        ifAmtFirstOverdueRate: 'Amount first overdue rate',
        ifAmtUnSettleRate: 'Amount unsettle rate',
        ifAmtEarlySettledRate: 'Amount early settled rate',
        ifAmtOverdueSettledRate: 'Amount overdue settled rate',
        ifAmtExtendsionSettledRate: 'Amount extendsion settled rate',
        ifAmtOverdueExtendsionSettledRate: 'Amount overdue extendsion settled rate',
        ifAmtSettledRate: 'Amount settled rate',
        ifLoanCount: 'Loan count',
        operationTime: 'operation Time',
        operationUserName: 'operation User Name',
        operationUserMobile: 'operation User Mobile',
        operationType: 'operationType',
        taskIdOld: 'taskId Old',
        taskIdNew: 'taskId New',
        orderInfoId: 'Order info id',
        custInfoId: 'Customer info id',
        waitingForCollection: 'Waiting For Collection',
        answeredByThemSelf: 'answered by them self',
        notInPerson: 'Not in person',
        noAnswer: 'no answer',
        emptyNumber: 'empty number',
        turnedOff: 'turned off',
        unavailable: 'unavailable',
        whatsappPromisesToPayBackOnTime: 'Whatsapp promises to pay back on time',
        WhatsappDidNotReply: 'Whatsapp did not reply',
        NoTieneCuentaDeWhatsapp: 'No tiene cuenta de whatsapp',
        haveAWishAndCanReturnTheLoan: 'have a wish and can return the loan',
        haveAWishButCantReturnTheLoan: `have a wish but can't return the loan`,
        canReturnTheLoanButRefuseToPayIt: 'can return the loan but refuse to pay it',
        canReturnTheLoanButWantToConsult: 'can return the loan but want to consult',
        cantJudgeItForNow: `can't judge it for now`,
        LaPersonaDeContactoPuedeComunicarleAlClienteParaElReembolso: 'The contact person can notify the customer of a refund ',
        ElContactoNoPuedeComunicarse: 'Contact person unable to communicate ',
        LaInformaciónDelContactoEsFalsa: 'The contact information is incorrect ',
        createdTime: 'Create Time',
        id: 'ID',
        remark: 'description',
    }
}
